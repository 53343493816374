<template>
  <div class="partner">
    <div class="partner_banner">
      <img
        src="https://qxqy-online.sdhckjvip.com/official/banner/banner_32x.png"
        alt=""
      />
    </div>
    <div class="partner_container">
      <div class="box partner_container_content">
        <div>全方位的平台优势</div>
        <div ref="boxOneRef">全方位一站式服务，加速私域场景运营</div>
        <div
          class="partner_container_content_box"
          :class="{ view_to_bottom: boxOneShow }"
        >
          <div v-for="(item, index) in tipsList" :key="index">
            <div
              class="partner_container_content_box_list"
              :class="{ active: tipsIndex === index }"
              @mouseenter="tipsIndex = index"
            >
              <img :src="item.icon" alt="" />
              <div class="partner_container_content_box_list_info">
                <div>{{ item.title }}</div>
                <div>
                  {{ item.content }}
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "partner",
  data() {
    return {
      tipsList: [
        {
          id: 1,
          title: "全方位服务",
          content:
            "提供培训、话术、物料、售后处理、疑问解答、线报攻略等专业1V1客服在线服务",
          icon: require("../../assets/partner/icon1.png"),
        },
        {
          id: 2,
          title: "平台扶持",
          content:
            "为合作商建设私域及运营，并组织培训、信息共享等平台扶持，或根据需求选择代运营",
          icon: require("../../assets/partner/icon2.png"),
        },
        {
          id: 3,
          title: "权益保障",
          content: "利用碎片化时间完成交易，实时结算，保障权益，引爆全民副业",
          icon: require("../../assets/partner/icon3.png"),
        },
        {
          id: 4,
          title: "零成本运营",
          content: "零成本，零押金，零风险，轻松创业成就梦想",
          icon: require("../../assets/partner/icon4.png"),
        },
      ],
      tipsIndex: -1,
      currentScroll: 0,
      boxOneShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handalScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handalScroll, true);
  },
  methods: {
    handalScroll() {
      this.currentScroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (this.currentScroll * 2 >= this.$refs.boxOneRef.offsetTop) {
        this.boxOneShow = true;
      }
    },
  },
};
</script>
<style scoped>
div {
  box-sizing: border-box;
}
.partner_banner {
  width: 100%;
  height: 700px;
}
.partner_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.partner_container {
  width: 100%;
  min-height: 840px;
  background-image: url("https://qxqy-online.sdhckjvip.com/official/cooperative_bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.partner_container_content {
  padding-top: 126px;
}
.partner_container_content > div:nth-child(1) {
  text-align: center;
  font-size: 40px;
  color: #292c33;
  font-weight: 600;
}
.partner_container_content > div:nth-child(2) {
  text-align: center;
  font-size: 20px;
  color: #85888c;
  font-weight: 500;
  margin-top: 16px;
}
.partner_container_content_box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 82px;
  transform: translateY(100px);
  opacity: 0;
}
.partner_container_content_box > div {
  width: 50%;
  padding-bottom: 30px;
}
.partner_container_content_box > div:nth-child(2n) {
  display: flex;
  justify-content: flex-end;
}
.partner_container_content_box_list {
  position: relative;
  display: flex;
  width: 590px;
  /* height: 175px; */
  background: #fff;
  border-radius: 8px;
  padding: 40px;
  transition: ease all 0.5s;
  cursor: pointer;
}
.partner_container_content_box_list > img {
  width: 64px;
  height: 64px;
}
.partner_container_content_box_list_info {
  flex: 1;
  margin-left: 24px;
}
.partner_container_content_box_list_info > div:nth-child(1) {
  font-size: 28px;
  color: #292c33;
  font-weight: 600;
}
.partner_container_content_box_list_info > div:nth-child(2) {
  font-size: 16px;
  color: #69728c;
  font-weight: 500;
  margin-top: 8px;
}
.partner_container_content_box_list > div:nth-child(3) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 6px;
  background: linear-gradient(270deg, #82d6ff 0%, #0055ff 100%);
  box-shadow: 0px 4px 4px -2px rgba(0, 85, 255, 0.24);
  border-radius: 3px;
  transition: ease all 0.5s;
}

.active {
  transform: translateY(-10px);
}

.active > div:nth-child(3) {
  width: 100%;
}

.view_to_bottom {
  transform: translateY(0) !important;
  opacity: 1 !important;
  transition: all 0.5s ease;
}
@media (max-width:800px){
  .partner_container_content_box_list_info > div:nth-child(1) {
    font-size: 46px;
  }
 .partner_container_content_box_list_info > div:nth-child(2) {
    font-size: 36px;
  }
}
@media (max-width: 1200px) {
  .partner_container_content_box{
    opacity: 1;
    transform: translateY(0px);
  }
  .partner_container_content_box_list{
    width: 90%;
  }
  .partner_container_content_box_list_info > div:nth-child(1) {
    font-size: 46px;
  }
 .partner_container_content_box_list_info > div:nth-child(2) {
    font-size: 36px;
  }
}

@media (max-width: 1600px) {
  .partner_container_content_box{
    opacity: 1;
    transform: translateY(0px);
  }
  
}
</style>